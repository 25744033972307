import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const Home = () => (
  <Layout>
    <article>
      <p>I’m a multi-disciplinary designer who’s passionate about creating products that are both functional and beautiful. From front-end design systems to brand strategy, I’m excited about building products that make people’s lives better.</p>
    </article>
    <article>
      <h3>Selected Projects</h3>
      <ul>
        <li><Link to='/work/pearson'>Pearson Accelerated Pathways</Link></li>
        <li><Link to='/work/candidate'>Candidate Form</Link></li>
      </ul>
    </article>
  </Layout>
)

export default Home
